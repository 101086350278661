<template>
	<div class="chat-powered-by">
		Powered by
		<a href="https://saltzap.com?utm_source=n8n-external&utm_medium=widget-powered-by">n8n</a>
	</div>
</template>

<style lang="scss">
.chat-powered-by {
	text-align: center;

	a {
		color: var(--chat--color-primary);
		text-decoration: none;
	}
}
</style>
